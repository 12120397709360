import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useClerk} from "@clerk/clerk-react";

const AuthLogoutPage = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {signOut} = useClerk();

    const navigate = useNavigate();

    const logout = async () => {
        await signOut();
        navigate("/");
    }

    useEffect(() => {
        logout();
    });

    return (
        <>Loading...</>
    );
}

export default AuthLogoutPage;
