import axios from "../utils/axios";

type apiServiceParams = {
    token: string,
    orgId?: string,
}

type paginationPayload = {
    pageIndex: number,
    pageSize: number,
}

const configGenerator = (params: apiServiceParams) => {
    const {token, orgId = ""} = params
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            "X-Organization-Id": orgId,
        },
    }
}

const configUploadGenerator = (params: apiServiceParams) => {
  const { token, orgId = "" } = params;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Organization-Id": orgId,
      "Content-Type": "multipart/form-data",
    },
  };
};

class TestService {
    getTest = (params: apiServiceParams) => axios.get("/", configGenerator(params))
}

class CustomerService {
    create = (payload: any, params: apiServiceParams) => axios.put("/customers", payload, configGenerator(params))
    delete = (CustomerRef: string, params: apiServiceParams) => axios.delete(`/customers/${CustomerRef}`, configGenerator(params))
    // edit = (uuid: string, params: apiServiceParams) => axios.get("/", configGenerator(params))
    getOne = (CustomerRef: string, params: apiServiceParams) => axios.get(`/customers/${CustomerRef}`, configGenerator(params))
    getList = (payload: paginationPayload, params: apiServiceParams) => axios.get(`/customers?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
    // delete = (uuid: string, params: apiServiceParams) => axios.get("/", configGenerator(params))

    stats = (params: apiServiceParams) => axios.get("/customers/stats", configGenerator(params))
    scan = (payload: any, params: apiServiceParams) => axios.post("/customers/scan", payload, configGenerator(params))
}


class CustomerContactsService {
    create = (CustomerRef: string, payload: any, params: apiServiceParams) => axios.put(`/customers/${CustomerRef}/contacts`, payload, configGenerator(params))
    update = (CustomerRef: string, ContactRef: string, payload: any, params: apiServiceParams) => axios.post(`/customers/${CustomerRef}/contacts/${ContactRef}`, payload, configGenerator(params))
    delete = (CustomerRef: string, ContactRef: string, params: apiServiceParams) => axios.delete(`/customers/${CustomerRef}/contacts/${ContactRef}`, configGenerator(params))
    getOne = (CustomerRef: string, ContactRef: string, params: apiServiceParams) => axios.get(`/customers/${CustomerRef}/contacts/${ContactRef}`, configGenerator(params))
    getList = (CustomerRef: string, payload: paginationPayload, params: apiServiceParams) => axios.get(`/customers/${CustomerRef}/contacts?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
}


class CustomerLocationsService {
    create = (CustomerRef: string, payload: any, params: apiServiceParams) => axios.put(`/customers/${CustomerRef}/locations`, payload, configGenerator(params))
    update = (CustomerRef: string, LocationRef: string, payload: any, params: apiServiceParams) => axios.post(`/customers/${CustomerRef}/locations/${LocationRef}`, payload, configGenerator(params))
    delete = (CustomerRef: string, LocationRef: string, params: apiServiceParams) => axios.delete(`/customers/${CustomerRef}/locations/${LocationRef}`, configGenerator(params))
    getOne = (CustomerRef: string, LocationRef: string, params: apiServiceParams) => axios.get(`/customers/${CustomerRef}/locations/${LocationRef}`, configGenerator(params))
    getList = (CustomerRef: string, payload: paginationPayload, params: apiServiceParams) => axios.get(`/customers/${CustomerRef}/locations?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
}

class CouriersService {
    create = (payload: any, params: apiServiceParams) => axios.put(`/couriers`, payload, configGenerator(params))
    update = (CourierRef: string, payload: any, params: apiServiceParams) => axios.post(`/couriers/${CourierRef}`, payload, configGenerator(params))
    delete = (CourierRef: string, params: apiServiceParams) => axios.delete(`/couriers/${CourierRef}`, configGenerator(params))
    getOne = (CourierRef: string, params: apiServiceParams) => axios.get(`/couriers/${CourierRef}`, configGenerator(params))
    getList = (payload: paginationPayload, params: apiServiceParams) => axios.get(`/couriers?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
}

class UserService {
    getOne = (UserRef: string, params: apiServiceParams) => axios.get(`/users/${UserRef}`, configGenerator(params))
}

class InvoicesService {
    getOne = (InvoiceRef: string, params: apiServiceParams) => axios.get(`/invoices/${InvoiceRef}`, configGenerator(params))
    update = (InvoiceRef: string, payload: any, params: apiServiceParams) => axios.post(`/invoices/${InvoiceRef}`, payload, configGenerator(params));
    upload = (payload: any, params: apiServiceParams) => axios.post(`/invoices/upload`, payload, configUploadGenerator(params));
    getList = (payload: paginationPayload, params: apiServiceParams) => axios.get(`/invoices?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))

    getLink = (InvoiceRef: string, params: apiServiceParams) => axios.get(`/invoices/${InvoiceRef}/getLink`, configGenerator(params))
    getListByCustomer = (CustomerRef: string, payload: paginationPayload, params: apiServiceParams) => axios.get(`/invoices?CustomerRef=${CustomerRef}&page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
    stats = (params: apiServiceParams) => axios.get("/invoices/stats", configGenerator(params))
}

class DeliveriesService {
    create = (payload: any, params: apiServiceParams) => axios.put(`/deliveries`, payload, configGenerator(params))
    update = (DeliveryRef: string, payload: any, params: apiServiceParams) => axios.post(`/deliveries/${DeliveryRef}`, payload, configGenerator(params))
    delete = (DeliveryRef: string, params: apiServiceParams) => axios.delete(`/deliveries/${DeliveryRef}`, configGenerator(params))
    getOne = (DeliveryRef: string, params: apiServiceParams) => axios.get(`/deliveries/${DeliveryRef}`, configGenerator(params))
    getList = (payload: paginationPayload, params: apiServiceParams) => axios.get(`/deliveries?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
    getListByCustomer = (CustomerRef: string, payload: paginationPayload, params: apiServiceParams) => axios.get(`/deliveries?CustomerRef=${CustomerRef}&page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
    getActivities = (DeliveryRef: string, params: apiServiceParams) => axios.get(`/deliveries/${DeliveryRef}/activities`, configGenerator(params))
    sendLink = (DeliveryRef: string, params: apiServiceParams) => axios.post(`/deliveries/${DeliveryRef}/sendLink`, {}, configGenerator(params))
}




export const testService = new TestService()
export const customerService = new CustomerService()
export const customerContactsService = new CustomerContactsService()
export const customerLocationsService = new CustomerLocationsService()
export const userService = new UserService()
export const couriersService = new CouriersService()
export const invoicesService = new InvoicesService();

export const deliveriesService = new DeliveriesService();