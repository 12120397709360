export enum ACTION_TYPES {
    FETCH_START = 'FETCH_START',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_ERROR = 'FETCH_ERROR',
    SET_PAGINATION = "SET_PAGINATION"
}

export interface IPagination {
    pageIndex: number;
    pageSize: number;
}

export interface IState<T> {
    isFetching: boolean;
    errorMessage: string;
    data: T[];
    pagination: IPagination;
    rowCount: number;
}

export const INITIAL_STATE: IState<any> = {
    isFetching: false,
    errorMessage: "",
    data: [],
    pagination: {
        pageIndex: 0,
        pageSize: 20,
    },
    rowCount: 0,
};

interface FetchStartAction {
    type: ACTION_TYPES.FETCH_START;
}

interface FetchSuccessAction<T> {
    type: ACTION_TYPES.FETCH_SUCCESS;
    payload: {
        data: T[];
        rowCount: number;
    };
}

interface FetchErrorAction {
    type: ACTION_TYPES.FETCH_ERROR;
    payload: string;
}

interface SetPaginationAction {
    type: ACTION_TYPES.SET_PAGINATION;
    payload: {
        pageIndex: number;
        pageSize: number;
    };
}

type ActionTypes<T> = FetchStartAction | FetchSuccessAction<T> | FetchErrorAction | SetPaginationAction;

export function reducer<T>(state: IState<T>, action: ActionTypes<T>): IState<T> {
    switch (action.type) {
        case ACTION_TYPES.FETCH_START:
            return {
                ...state,
                isFetching: true,
                errorMessage: "",
            };
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorMessage: "",
                data: action.payload.data,
                rowCount: action.payload.rowCount,
            };
        case ACTION_TYPES.FETCH_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
                data: [],
                rowCount: 0,
            };
        case ACTION_TYPES.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
}
