import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from "react-router-dom";
import {OrganizationSwitcher} from "@clerk/clerk-react";
import {Theme} from "@mui/material/styles";
import {createStyles, makeStyles} from '@mui/styles';
import {Box, Typography, Chip} from "@mui/material";
import Navigation from './Navigation/index.jsx';


const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawerFooter: {
        marginTop: 'auto'
    }
}))

export default function PermanentDrawerLeft() {
    const navigate = useNavigate();
    const classes = useStyles();
    const currentRoute = window.location.pathname;
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar>Collect Easy logo here!</Toolbar>
            <Divider/>

            <Navigation/>

            {/*<List
            {/*    sx={{mb: 0, py: 0, zIndex: 0}}*/}
            {/*    subheader={(*/}
            {/*        <Box sx={{pl: 3, mb: 1.5}}>*/}
            {/*            <Typography variant="subtitle2" color="textSecondary">*/}
            {/*                General*/}
            {/*            </Typography>*/}
            {/*            */}{/*{/* only available in paid version */}
            {/*        </Box>*/}
            {/*    )}*/}
            {/*>*/}
            {/*    <ListItem disablePadding onClick={() => navigate("/")}>*/}
            {/*        <ListItemButton selected={currentRoute === "/"}>*/}
            {/*            <ListItemText primary={"Dashboard"}/>*/}
            {/*        </ListItemButton>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem disablePadding onClick={() => navigate("/customers")}>*/}
            {/*        <ListItemButton selected={currentRoute === "/customers"}>*/}
            {/*            <ListItemText primary={"Customers"}/>*/}
            {/*        </ListItemButton>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem disablePadding onClick={() => navigate("/documents")}>*/}
            {/*        <ListItemButton selected={currentRoute === "/documents"}>*/}
            {/*          <ListItemText primary={"Documents"} />*/}
            {/*        </ListItemButton>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem disablePadding onClick={() => navigate("/deliveries")}>*/}
            {/*        <ListItemButton selected={currentRoute === "/deliveries"}>*/}
            {/*            <ListItemText primary={"Deliveries"}/>*/}
            {/*        </ListItemButton>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem disablePadding onClick={() => navigate("/couriers")}>*/}
            {/*        <ListItemButton selected={currentRoute === "/couriers"}>*/}
            {/*            <ListItemText primary={"Couriers"}/>*/}
            {/*        </ListItemButton>*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
            <div className={classes.drawerFooter}>
                <ListItem disablePadding onClick={() => navigate("/changelog")}>
                    <ListItemButton selected={currentRoute === "/changelog"}>
                        <ListItemText primary={"Changelog"}/>
                        <div dangerouslySetInnerHTML={{__html: '<released-badge channel-id="d02cf48f-6fdc-4857-850c-e25ec5464d6d"></released-badge>'}} />
                    </ListItemButton>
                </ListItem>
                <Divider/>
                <List>
                    <ListItem>
                        <OrganizationSwitcher
                            hidePersonal={true}
                            organizationProfileMode="navigation"
                            organizationProfileUrl="/settings/tenant"
                            afterSelectOrganizationUrl="/"
                            afterSelectPersonalUrl="/"
                            appearance={{
                                elements: {
                                    rootBox: {
                                        width: "100%",
                                    },
                                    organizationSwitcherTrigger: {
                                        width: "100%"
                                    }
                                }
                            }}
                        />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
}
