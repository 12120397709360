// assets
import {LoginOutlined, ProfileOutlined, UsergroupAddOutlined} from '@ant-design/icons';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';

// icons
const icons = {
	LoginOutlined,
	ProfileOutlined,
	UsergroupAddOutlined,
	DescriptionOutlinedIcon,
	DeliveryDiningOutlinedIcon
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
	id: 'platform',
	title: 'Platform',
	type: 'group',
	children: [
		{
			title: 'Customers',
			type: 'item',
			url: '/customers',
			icon: icons.UsergroupAddOutlined,
		},
		{
			title: 'Documents',
			type: 'item',
			url: '/documents',
			icon: icons.DescriptionOutlinedIcon,
		},
		{
			title: 'Deliveries',
			type: 'item',
			url: '/deliveries',
			icon: icons.DeliveryDiningOutlinedIcon,
		}
	]
};

export default pages;
