import React, {useEffect, useMemo, useReducer} from "react";

import {MaterialReactTable} from 'material-react-table';
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import {useAuth} from "@clerk/clerk-react";
import {Avatar, Chip, Box, Button} from "@mui/material";

import {IconButton} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Link} from "react-router-dom";

import FaceIcon from '@mui/icons-material/Face';
import InvoiceStatusChip from "@/components/Common/InvoiceStatusChip";

import {deliveriesService} from "@/services/api.service";
import {ACTION_TYPES, INITIAL_STATE, reducer, IState} from '@/reducers/commonList'
import DeliveryDialog from "@/components/Deliveries/DeliveryDialog";


interface IDelivery {
    _id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
}

interface IDeliveries {
    rows: IDelivery[];
    rowCount: number;
}

interface IInvoice {
    _id: string;
    documentId: string;
    Customer: {
        title: {
            he: string;
            en: string;
        }
    }
}

const DeliveriesList = (props: any) => {
    const { CustomerRef = false } = props;

    const {getToken, orgId, isLoaded} = useAuth();
    const [state, dispatch] = useReducer(reducer<IDelivery>, INITIAL_STATE as IState<IDelivery>);


    useEffect(() => {
        fetchData()
    }, [state.pagination, orgId])

    const fetchData = async () => {
        const token = await getToken()
        if (!token || !orgId) return
        dispatch({type: ACTION_TYPES.FETCH_START})

        try {
            const response = CustomerRef
            ? await deliveriesService.getListByCustomer(CustomerRef, state.pagination, {token, orgId})
            : await deliveriesService.getList(state.pagination, {token, orgId})
            if (response.data.ok) {
                dispatch({
                    type: ACTION_TYPES.FETCH_SUCCESS, payload: {
                        data: response.data.data.rows,
                        rowCount: response.data.data.rowCount
                    }
                })
            } else {
                dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: response.data.message || "Unknown error"})
            }
        } catch (e) {
            dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: "Unknown error"})
        }
    }

    const columns = useMemo(
        () => [
            {
                header: 'Invoices',
                accessorKey: 'Invoices',
                Cell: ({cell}: any) => (
                    <>
                        {cell.getValue().map(((invoice: IInvoice) => {
                            return (
                                <>#{invoice.documentId} (<small>{invoice.Customer.title.he}</small>)<br/></>
                            )
                        }))}
                    </>
                )
            },
            {
                header: 'Courier',
                accessorKey: 'Courier.name',
                Cell: ({cell}: any) => (
                    <Chip
                        size={"small"}
                        avatar={<Avatar alt={cell.getValue()}></Avatar>}
                        label={cell.getValue()}
                    />
                )
            },
            {
                header: 'Delivery date',
                accessorKey: 'deliveryDate',
                Cell: ({cell}: any) => new Date(cell.getValue()).toDateString()
            },
            {
                header: 'Status',
                accessorKey: 'status',
                Cell: ({cell}: any) => <InvoiceStatusChip status={cell.getValue()} />
            },
            {
                header: 'Created At',
                accessorKey: 'createdAt',
                Cell: ({cell}: any) => new Date(cell.getValue()).toLocaleString()
            },
            {
                header: 'Updated At',
                accessorKey: 'updatedAt',
                Cell: ({cell}: any) => new Date(cell.getValue()).toLocaleString()
            }
        ],
        [],
    );

    return (
        <React.Fragment>
            <MaterialReactTable
                initialState={{
                    density: "compact",
                    isLoading: true
                }}
                // onPaginationChange={state.setPagination}
                state={{
                    isLoading: state.isFetching,
                    pagination: state.pagination,
                }}
                manualPagination={true}
                rowCount={state.rowCount}
                pageCount={Math.ceil(state.rowCount / state.pagination.pageSize)}
                // @ts-ignore
                columns={columns}
                data={state.data}
                renderTopToolbarCustomActions={() => (
                    <DeliveryDialog onFinish={() => fetchData()}/>
                )}
                enableTopToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableSorting={false}
                enableRowActions={true}
                renderRowActions={({row, table}) => (
                    <>
                    {/*<Box sx={{display: 'flex', gap: '1rem'}}>*/}
                    {/*    <DeliveryDialog onFinish={fetchData} DeliveryRef={row.original._id}/>*/}
                    {/*</Box>*/}
                        <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                            <Link to={`/deliveries/${(row as MRT_Row<IDelivery>).original._id}/view`}>
                                <IconButton color="secondary">
                                    <RemoveRedEyeIcon/>
                                </IconButton>
                            </Link>
                        </Box>
                    </>
                )}
            />
        </React.Fragment>
    );
}

export default DeliveriesList;
