import HeaderComponent from "@/layouts/MainLayout/components/Header";
import {Grid} from "@mui/material";
import CouriersList from "@/components/Couriers/CouriersList";

const CouriersContainer = () => {

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sx={{mb: -2.25}}>
                <HeaderComponent title="Couriers"/>
            </Grid>

            <Grid item md={8} sx={{display: {sm: 'none', md: 'block', lg: 'none'}}}/>

            <Grid item xs={12} md={12} lg={12}>
                <CouriersList/>
            </Grid>
        </Grid>
    );
}

export default CouriersContainer;
