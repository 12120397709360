import {useParams} from 'react-router-dom';
import HeaderComponent from "@/layouts/MainLayout/components/Header";
import {Alert, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAuth} from "@clerk/clerk-react";
import {customerService} from "@/services/api.service";
import LinearProgress from "@mui/material/LinearProgress";
import CustomerCardDetails from "@/components/Customers/Details/CustomerCardDetails";
const CustomerContainer = () => {
    const [isFetching, setFetching] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [customer, setCustomer] = useState<any | null>(null); // TODO: define customer type
    const {getToken, orgId} = useAuth();
    const {uuid, action = "view"} = useParams();

    useEffect(() => {
        fetchData()
    }, [uuid]);

    const fetchData = async () => {
        const token = await getToken();
        if (!uuid || !token || !orgId) return;
        try {
            const response = await customerService.getOne(uuid, {token, orgId});
            if (response.data.ok) {
                setCustomer(response.data.data);
                setErrorMessage(null)
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (e) {
            setErrorMessage("Something went wrong");
        } finally {
            setFetching(false);
        }
    }

    return (
        <div>
            <HeaderComponent title={customer ? `Customer "${customer.title.he}"` : "Loading"} links={[
                {title: "Customers", href: "/customers"},
            ]}/>
            {isFetching && <LinearProgress/>}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            <CustomerCardDetails customer={customer}/>
        </div>
    );
}

export default CustomerContainer;
