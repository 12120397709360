import { Chip, ChipProps } from "@mui/material";

type InvoiceStatusChipProps = {
	status: ChipProps["label"],
}

const InvoiceStatusChip = (props: InvoiceStatusChipProps) => {
	const { status = "unknown" } = props;
	let color: ChipProps["color"] = "primary";
	let variant: ChipProps["variant"] = "filled";
	switch(status) {
		case "creating":
			color = "primary";
			variant = "outlined"
			break;
			case "created":
				color = "primary";
				variant = "outlined"
			break;
		default:
			color = "primary";
			break;
	}
	return <Chip label={status} size="small" color={color} variant={variant}/>
}

export default InvoiceStatusChip;
