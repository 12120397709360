// @ts-ignore
import ThemeCustomization from './themes';
import {ClerkLoaded, ClerkLoading, ClerkProvider} from "@clerk/clerk-react";
import AppContainer from "@/containers/App/App";
import {useNavigate} from "react-router-dom";
import LoadingComponent from "@/components/Common/LoadingComponent";

const clerk_pub_key = "pk_test_cmVuZXdpbmctbW91c2UtNjguY2xlcmsuYWNjb3VudHMuZGV2JA"


const App = () => {
    const navigate = useNavigate()
    return (
        <ThemeCustomization>
            <ClerkProvider
                publishableKey={clerk_pub_key}
                appearance={{
                    elements: {
                        footer: "hidden",
                    },
                    variables: {
                        borderRadius: "0px"
                    }
                }}
                routerPush={(to) => navigate(to)}
                routerReplace={(to) => navigate(to)}
            >
                <ClerkLoading>
                    <LoadingComponent/>
                </ClerkLoading>
                <ClerkLoaded>
                    <AppContainer/>
                </ClerkLoaded>
            </ClerkProvider>
        </ThemeCustomization>
    )
}

export default App
