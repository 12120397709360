// assets
import {DashboardOutlined} from '@ant-design/icons';

// icons
const icons = {
	DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
	id: 'navigation',
	title: 'Navigation',
	type: 'group',
	children: [
		{
			title: 'Dashboard',
			type: 'item',
			url: '/',
			icon: icons.DashboardOutlined,
		}
	]
};

export default dashboard;
 