import MinimalLayout from "@/layouts/MinimalLayout";
import LoginPage from "@/containers/Auth/Login";
import SignupPage from "@/containers/Auth/Signup";
import LogoutPage from "@/containers/Auth/Logout";

const AuthRoutes = {
    path: "/auth",
    element: <MinimalLayout/>,
    children: [
        {
            path: "login",
            element: <LoginPage/>
        },
        {
            path: "signup",
            element: <SignupPage/>
        },
        {
            path: "logout",
            element: <LogoutPage/>
        }
    ]
};
export default AuthRoutes;
