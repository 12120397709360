import {Box, Button, Card, CardContent, CardHeader, Divider, IconButton, Tooltip, Checkbox} from "@mui/material";
import React, {useEffect, useMemo, useReducer} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {useAuth} from "@clerk/clerk-react";
import {invoicesService} from "@/services/api.service";
import {ACTION_TYPES, INITIAL_STATE, reducer, IState} from '@/reducers/commonList'
import { TInvoice } from "@/interfaces/types"
import InvoiceStatusChip from "@/components/Common/InvoiceStatusChip";

import DocumentViewerDialog from "@/components/Documents/DocumentViewerDialog";


const DeliveryInvoicesList = (props: any) => {
  const { data } = props;

  const {getToken, orgId, isLoaded} = useAuth();
  

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "_id"
      },
      {
        header: "Customer",
        accessorKey: "Customer.title.he",
        Cell: ({cell}: any) => cell.getValue()
      },
      {
        header: "Invoice ID",
        accessorKey: "documentId"
      },
      {
        header: "Delivery comment",
        accessorKey: "documentComment"
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({cell}: any) => <InvoiceStatusChip status={cell.getValue()} />
      },
    ],
    [],
    );

  return (
    <Card elevation={0} variant="outlined">
      <CardHeader
        title="Invoices"
      />
      <Divider/>
      <CardContent style={{padding: "0"}}>
        <MaterialReactTable
          initialState={{
            density: "compact",
            isLoading: false
          }}
          rowCount={data.length}
          // @ts-ignore
          columns={columns}
          data={data}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableSorting={true}
          enableRowActions={true}
          enablePagination={false}
          renderRowActions={({row, table}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
              <DocumentViewerDialog InvoiceRef={row.original._id} />
            </Box>
            )}
        />
      </CardContent>
    </Card>
  );
};

export default DeliveryInvoicesList;
