import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField} from "@mui/material";
import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useAuth} from "@clerk/clerk-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import * as yup from 'yup';
import {customerContactsService} from "@/services/api.service";
import {enqueueSnackbar} from "notistack";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";

const ContactDialog = (props: any) => {
    const {onFinish, CustomerRef, ContactRef = null} = props;

    const {getToken} = useAuth()
    const [open, setOpen] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(!!ContactRef);

    const handleOpenDialog = () => setOpen(true)
    const handleCloseDialog = () => {
        setOpen(false);
        formik.resetForm();
    }

    const validationSchema = yup.object({
        "name": yup.string().required("Name is required"),
        "phone": yup.string().when("phoneNotification", {
            is: true,
            then: (schema) => schema.required("Phone is required"),
            otherwise: (schema) => schema.notRequired()
        }),
        "email": yup.string().email("Should be valid email").when("emailNotification", {
            is: true,
            then: (schema) => schema.required("Email is required"),
            otherwise: (schema) => schema.notRequired()
        })
    });

    const deleteData = async () => {
        if (!ContactRef) return;
        await setFetching(true);
        const token = await getToken();
        if (!token) return
        const response = await customerContactsService.delete(CustomerRef, ContactRef, {token});
        if (response.data.ok) {
            enqueueSnackbar('Contact deleted successfully', {variant: 'success'})
            handleCloseDialog();
            onFinish();
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const fetchData = async () => {
        // Fetch data from api (customerContactsService.getOne) and set to formik
        const token = await getToken();
        if (!token) return
        await setFetching(true);
        const response = await customerContactsService.getOne(CustomerRef, ContactRef, {token});
        setFetching(false);
        if (response.data.ok) {
            await formik.setValues(response.data.data);
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const submitData = async (payload: any) => {
        await setFetching(true);
        const token = await getToken();
        if (!token) return

        if (ContactRef) {
            const response = await customerContactsService.update(CustomerRef, ContactRef, payload, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Contact updated successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
            return;
        } else {
            const response = await customerContactsService.create(CustomerRef, payload, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Contact created successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
        }
    }


    const formik = useFormik({
        initialValues: {
            name: "",
            position: "",
            email: "",
            phone: "",
            phoneNotification: false,
            emailNotification: false
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await submitData(values);
            // const response = customerService.create()
            // alert(JSON.stringify(values, null, 2));
        },
    });


    useEffect(() => {
        if (!open || !ContactRef) return;
        fetchData();
    }, [open])
    return (
        <>
            <IconButton aria-label="settings" onClick={handleOpenDialog}>
                {ContactRef ? <EditIcon/> : <AddBoxIcon/>}
            </IconButton>
            <Dialog open={open} onClose={handleCloseDialog} fullWidth={true}>
                <DialogTitle>{ContactRef ? "Edit contact" : "Create contact"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    value={formik.values["name"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["name"] && Boolean(formik.errors["name"])}
                                    helperText={formik.touched["name"] && formik.errors["name"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="position"
                                    label="Position"
                                    variant="filled"
                                    value={formik.values["position"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["position"] && Boolean(formik.errors["position"])}
                                    helperText={formik.touched["position"] && formik.errors["position"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="phone"
                                    label="Phone"
                                    variant="filled"
                                    value={formik.values["phone"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["phone"] && Boolean(formik.errors["phone"])}
                                    helperText={formik.touched["phone"] && formik.errors["phone"]}
                                />
                                <br/>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox name="phoneNotification" onChange={formik.handleChange} checked={formik.values["phoneNotification"]}/>}
                                                      label="Send SMS notifications"/>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    value={formik.values["email"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["email"] && Boolean(formik.errors["email"])}
                                    helperText={formik.touched["email"] && formik.errors["email"]}
                                />
                                <br/>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox name="emailNotification" onChange={formik.handleChange} checked={formik.values["emailNotification"]}/>}
                                                      label="Send mail notifications"/>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    {ContactRef && (
                        <ConfirmationDialog
                            message="Are you sure you want to delete this contact?"
                            confirmButton="Yes, delete"
                            cancelButton="Cancel"
                            onConfirm={deleteData}
                        >
                            <Button disabled={isFetching} variant="outlined" color="error">Delete</Button>
                        </ConfirmationDialog>
                    )}
                    <Box flexGrow={1}/>
                    <Button onClick={handleCloseDialog} disabled={isFetching}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained" disabled={isFetching}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ContactDialog;
