import {Navigate} from "react-router-dom";
import {useAuth} from "@clerk/clerk-react";

type AuthGuardProps = {
    children: JSX.Element;
}
const AuthGuard = ({children}: AuthGuardProps) => {
    const {isSignedIn} = useAuth();
    if (!isSignedIn) {
        return <Navigate to={"/auth/login"}/>
    } else {
        return children
    }
}

export default AuthGuard;
