import {OrganizationProfile} from "@clerk/clerk-react";
import {Grid} from "@mui/material";
import HeaderComponent from "@/layouts/MainLayout/components/Header";

import { Container, Paper, Typography, Button, Badge, Avatar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import SapBusinessOneLogo from "@/assets/images/integrations/sap.png"
import PriorityLogo from "@/assets/images/integrations/priority.png"
import SMTPLogo from "@/assets/images/integrations/smtp.png"
import TwilioLogo from "@/assets/images/integrations/twilio.png"

const systemIntegrations = [
    { name: 'Priority Software', description: 'Access customer details and invoices from Priority Software for efficient delivery management.', installed: false, logo: PriorityLogo },
    { name: 'SAP Business One', description: 'Retrieve customer data and invoices from SAP Business One to streamline delivery operations.', installed: false, logo: SapBusinessOneLogo }
  ];

const notificationsIntegrations = [
  { name: 'Custom SMTP', description: 'Sent email notifications to customer using your private SMTP server', installed: false, logo: SMTPLogo },
  { name: 'Twilio', description: 'Sent sms notifications to customer using your SMS account on Twilio', installed: false, logo: TwilioLogo }
];


const IntegrationCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: theme.shadows[4],
    },
  }));

const IntegrationsContainer = () => {
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sx={{mb: -2.25}}>
                <HeaderComponent title="Integrations"/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={6}>
                  {systemIntegrations.map((integration) => (
                    <Grid item xs={12} sm={6} lg={4} key={integration.name}>
                      <IntegrationCard>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex" alignItems="center" gap={2}>
                            <Avatar src={integration.logo} alt={integration.name} sx={{ width: 48, height: 48 }} />
                            <Box>
                              <Typography variant="h6">{integration.name}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {integration.description}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                          <Button variant={integration.installed ? 'outlined' : 'contained'} color="primary" size="small" disabled={true}>
                            {integration.installed ? 'Uninstall' : 'Install'}
                          </Button>
                        </Box>
                      </IntegrationCard>
                    </Grid>
                  ))}
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{mb: -2.25}}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={6}>
                  {notificationsIntegrations.map((integration) => (
                    <Grid item xs={12} sm={6} lg={4} key={integration.name}>
                      <IntegrationCard>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex" alignItems="center" gap={2}>
                            <Avatar src={integration.logo} alt={integration.name} sx={{ width: 48, height: 48 }} />
                            <Box>
                              <Typography variant="h6">{integration.name}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {integration.description}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                          <Button variant={integration.installed ? 'outlined' : 'contained'} color="primary" size="small" disabled={true}>
                            {integration.installed ? 'Uninstall' : 'Install'}
                          </Button>
                        </Box>
                      </IntegrationCard>
                    </Grid>
                  ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default IntegrationsContainer;
