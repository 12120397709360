import HeaderComponent from "@/layouts/MainLayout/components/Header";
import { Alert, Grid } from "@mui/material";

const DocumentsContainer = () => {

    return (
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <HeaderComponent title="Documents" />
        </Grid>

        <Grid item md={8} sx={{ display: { sm: "none", md: "block", lg: "none" } }} />

        <Grid item xs={12} md={12} lg={12}>
          <Alert severity={"warning"}>Document not avaiable for your Account</Alert>
          {/*<DocumentsList />*/}
        </Grid>
      </Grid>
    );
}

export default DocumentsContainer;
