import {Breadcrumbs, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

type BreadcrumbLink = {
    title: string,
    href: string
}

const Breadcrumb = (props: any) => {
    const {links = [], current = ""} = props;
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to={"/"}>
                    Dashboard
                </Link>
                {links.map((link: BreadcrumbLink, index: number) => (
                    <Link underline="hover" color="inherit" component={RouterLink} to={link.href} key={`link-${index}`}>
                        {link.title}
                    </Link>
                ))}
                <Typography color="text.primary">{current}</Typography>
            </Breadcrumbs>
            <br/>
        </>
    )
}

export default Breadcrumb;
