import {Grid} from "@mui/material";
import HeaderComponent from "@/layouts/MainLayout/components/Header";
import TotalCustomersCard from "@/components/Dashboard/TotalCustomersCard";
import TotalInvoicesCard from "@/components/Dashboard/TotalInvoicesCard";

const HomePage = () => {
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sx={{mb: -2.25}}>
                <HeaderComponent title="Dashboard" hideBreadcrumbs={true}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TotalCustomersCard/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TotalInvoicesCard/>
            </Grid>
            <Grid item md={8} sx={{display: {sm: 'none', md: 'block', lg: 'none'}}}/>
        </Grid>
    );
}

export default HomePage;
