import {Alert, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import CustomerCardContacts from "@/components/CustomerContacts/ContactsCard";
import ContactDialog from "@/components/CustomerContacts/ContactDialog";
import React from "react";
import CustomerDeleteDialog from "@/components/Customers/Details/CustomerDeleteDialog";
import UserChip from "@/components/Common/UserChip";
import CustomerCardLocations from "@/components/CustomerLocations/LocationsCard";
import DocumentsList from "@/components/Documents/DocumentsList";
import DeliveriesList from "@/components/Deliveries/DeliveriesList";

const CustomerField = (props: any) => {
    const {title, value} = props;
    return (
        <div>
            <Typography variant="body1" color="textSecondary">{title}</Typography>
            <Typography variant="body1">{value || "-"}</Typography>
        </div>
    )
}

const CustomerCardDetails = (props: any) => {
    const {customer} = props;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={9}>
                <Card elevation={0} variant="outlined">
                    <CardHeader title="Deliveries"/>
                    <Divider/>
                    <CardContent>
                        <Alert severity={"warning"}>Feature has been disabled for your Account</Alert>
                        {/*<DeliveriesList CustomerRef={customer?._id}/>*/}
                    </CardContent>
                </Card>
                <br/>
                <Card elevation={0} variant="outlined">
                    <CardHeader title="Documents"/>
                    <Divider/>
                    <CardContent>
                        <Alert severity={"warning"}>Feature has been disabled for your Account</Alert>
                        {/*{customer && <DocumentsList CustomerRef={customer?._id}/>}*/}
                    </CardContent>
                </Card>
                <br/>
                <CustomerCardContacts customerId={customer?._id}/>
                <br/>

                <CustomerCardLocations customerId={customer?._id}/>
            </Grid>
            <Grid item xs={3}>
                <Card elevation={0} variant="outlined">
                    <CardHeader title="General information"
                                action={
                                    <CustomerDeleteDialog customer={customer}/>
                                }
                    />
                    <Divider/>
                    <CardContent>
                        <List>
                            <ListItem>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <CustomerField title="TIN" value={customer?.companyNumber}/>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <CustomerField title="Name (HE)" value={customer?.title?.he}/>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <CustomerField title="Name (EN)" value={customer?.title?.en}/>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <br/>
                <Card elevation={0} variant="outlined">
                    <CardHeader title="Metadata"/>
                    <Divider/>
                    <CardContent>
                        <List>
                            <ListItem>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <CustomerField title="UUID" value={customer?._id}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CustomerField title="Created At" value={new Date(customer?.createdAt).toLocaleString()}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CustomerField title="Updated At" value={new Date(customer?.updatedAt).toLocaleString()}/>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="body1" color="textSecondary">Created By</Typography>
                                        <UserChip UserRef={customer?.createdBy}/>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default CustomerCardDetails;
