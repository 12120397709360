import {useEffect, useMemo, useState} from "react";
import {MaterialReactTable} from 'material-react-table';
import {useAuth} from "@clerk/clerk-react";
import {customerService} from "@/services/api.service";
import CustomerCreateDialog from "@/components/Customers/CustomerCreateDialog";
import {Box, IconButton} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Link} from "react-router-dom";
// import { TCustomer } from "@/interfaces/types";


const CustomersList = () => {
    const {getToken, orgId, isLoaded} = useAuth();
    const [customers, setCustomers] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(false);

    const fetchData = async () => {
        if (customers.length < 1) setFetching(true);
        setError(false);
        const token = await getToken();
        if (!token || !orgId) return
        try {
            const response = await customerService.getList(pagination, {token, orgId});
            if (response.data.status === 200) {
                setCustomers(response.data.data.rows);
                setRowCount(response.data.data.totalRows);
                setFetching(false);
                setError(false);
            } else {
                throw new Error("Error fetching data");
            }
        } catch (e) {
            setFetching(false);
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, [orgId, pagination])

    const columns = useMemo(
        () => [
            {
                header: 'TIN',
                accessorKey: 'companyNumber'
            },
            {
                header: 'Title',
                accessorFn: (row: any) => `${row.title.en} ${row.title.he}`,
                Cell: ({cell}: any) => (
                    <div>
                        {cell.row.original.title.he}<br/>
                        <small>{cell.row.original.title.en}</small>
                    </div>
                )
            },
            {header: 'Description', accessorKey: 'description'},
            {
                header: 'Created At',
                accessorKey: 'createdAt',
                Cell: ({cell}: any) => new Date(cell.getValue()).toLocaleString()
            },
            {
                header: 'Updated At',
                accessorKey: 'updatedAt',
                Cell: ({cell}: any) => new Date(cell.getValue()).toLocaleString()
            }
        ],
        [],
    );

    return (
        <div>
            <MaterialReactTable
                initialState={{
                    density: "compact",
                    isLoading: true
                }}
                onPaginationChange={setPagination}
                state={{
                    isLoading: fetching,
                    pagination,
                }}
                manualPagination={true}
                rowCount={rowCount}
                pageCount={Math.ceil(rowCount / pagination.pageSize)}
                // @ts-ignore
                columns={columns}
                data={customers}
                renderTopToolbarCustomActions={() => (
                    <CustomerCreateDialog onCustomerCreated={() => fetchData()}/>
                )}
                enableTopToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableSorting={false}
                enableRowActions={true}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                        <Link to={`/customers/${row.original._id}/view`}>
                            <IconButton color="secondary">
                                <RemoveRedEyeIcon/>
                            </IconButton>
                        </Link>
                    </Box>
                )}
            />
        </div>
    );
}

export default CustomersList;
