import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const DocumentViewer = (props: any) => {
	const { fileUrl } = props
	
	const [totalPages, setTotalPages] = useState<number>();
	const [currentPage, setCurrentPage] = useState<number>(1);
	

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => setTotalPages(numPages);
	
	if(!fileUrl) return;
	
	return (
		<div>
			<Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={currentPage} renderTextLayer={true}/>
			</Document>
		</div>
		);
}


export default DocumentViewer;