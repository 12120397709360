import AuthWrapper from './AuthWrapper';
import {SignIn} from "@clerk/clerk-react";

const AuthLoginPage = () => {


    return (
        <AuthWrapper>
            <SignIn
                appearance={{
                    elements: {
                        footer: {
                            display: "none"
                        }
                    }
                }}
            />
        </AuthWrapper>
    );
}

export default AuthLoginPage;
