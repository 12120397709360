import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField} from "@mui/material";
import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useAuth} from "@clerk/clerk-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import * as yup from 'yup';
import {deliveriesService} from "@/services/api.service";
import {enqueueSnackbar} from "notistack";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LocalizationProvider } from '@mui/x-date-pickers';

const DeliveryDialog = (props: any) => {
    const {onFinish, DeliveryRef = null} = props;

    const {getToken} = useAuth()
    const [open, setOpen] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(!!DeliveryRef);

    const handleOpenDialog = () => setOpen(true)
    const handleCloseDialog = () => {
        setOpen(false);
        formik.resetForm();
    }

    const deleteData = async () => {
        if (!DeliveryRef) return;
        await setFetching(true);
        const token = await getToken();
        if (!token) return
        const response = await deliveriesService.delete(DeliveryRef, {token});
        if (response.data.ok) {
            enqueueSnackbar('Delivery deleted successfully', {variant: 'success'})
            handleCloseDialog();
            onFinish();
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const fetchData = async () => {
        // Fetch data from api (customerContactsService.getOne) and set to formik
        const token = await getToken();
        if (!token) return
        await setFetching(true);
        const response = await deliveriesService.getOne(DeliveryRef, {token});
        setFetching(false);
        if (response.data.ok) {
            await formik.setValues({
                Courier: response.data.data.Courier,
                Invoice: response.data.data.Invoices.join(","),
                comment: response.data.data.comment,
                deliveryDate: new Date(response.data.data.deliveryDate)
            });
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const submitData = async (payload: any) => {
        await setFetching(true);
        const token = await getToken();
        if (!token) return

        if (DeliveryRef) {
            const response = await deliveriesService.update(DeliveryRef, {
                Courier: payload.Courier,
                Invoices: payload.Invoice.split(","),
                comment: payload.comment,
                deliveryDate: payload.deliveryDate
            }, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Delivery updated successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
            return;
        } else {
            const response = await deliveriesService.create({
                Courier: payload.Courier,
                Invoices: payload.Invoice.split(","),
                comment: payload.comment,
                deliveryDate: payload.deliveryDate
            }, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Delivery created successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
        }
    }


    const formik = useFormik({
        initialValues: {
            Invoice: "",
            Courier: "",
            comment: "",
            deliveryDate: new Date()
        },
        validationSchema: yup.object({
            "Invoice": yup.string().required("Invoice UUID is required"),
            "Courier": yup.string().required("Courier UUID is required"),
            "comment": yup.string(),
            "deliveryDate": yup.date().required("deliveryDate number is required")
        }),
        onSubmit: async (values) => {
            await submitData(values);
            // const response = customerService.create()
            // alert(JSON.stringify(values, null, 2));
        },
    });


    useEffect(() => {
        if (!open || !DeliveryRef) return;
        fetchData();
    }, [open])

    return (
        <>
            <IconButton aria-label="settings" onClick={handleOpenDialog}>
                {DeliveryRef ? <EditIcon/> : <AddBoxIcon/>}
            </IconButton>
            <Dialog open={open} onClose={handleCloseDialog} fullWidth={true}>
                <DialogTitle>{DeliveryRef ? "Edit delivery" : "Create delivery"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="Invoice"
                                    label="Invoice UUID"
                                    variant="filled"
                                    value={formik.values["Invoice"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["Invoice"] && Boolean(formik.errors["Invoice"])}
                                    helperText={formik.touched["Invoice"] && formik.errors["Invoice"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="Courier"
                                    label="Courier UUID"
                                    variant="filled"
                                    value={formik.values["Courier"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["Courier"] && Boolean(formik.errors["Courier"])}
                                    helperText={formik.touched["Courier"] && formik.errors["Courier"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="comment"
                                    label="comment"
                                    variant="filled"
                                    value={formik.values["comment"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["comment"] && Boolean(formik.errors["comment"])}
                                    helperText={formik.touched["comment"] && formik.errors["comment"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        onChange={(value) => formik.setFieldValue("deliveryDate", value, true)}
//                                        value={formik.values["deliveryDate"]}
//                                        error={formik.touched["deliveryDate"] && Boolean(formik.errors["deliveryDate"])}
//                                        helperText={formik.touched["deliveryDate"] && formik.errors["deliveryDate"]}
                                        label="Delivery Date"
                                        name="documentDate"
                                        disabled={isFetching}
                                        slotProps={{
                                            textField: {
                                                variant: "filled",
                                                fullWidth: true,
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    {DeliveryRef && (
                        <ConfirmationDialog
                            message="Are you sure you want to delete this courier?"
                            confirmButton="Yes, delete"
                            cancelButton="Cancel"
                            onConfirm={deleteData}
                        >
                            <Button disabled={isFetching} variant="outlined" color="error">Delete</Button>
                        </ConfirmationDialog>
                    )}
                    <Box flexGrow={1}/>
                    <Button onClick={handleCloseDialog} disabled={isFetching}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained" disabled={isFetching}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeliveryDialog;
