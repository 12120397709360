import {Avatar, Chip} from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import React, {useEffect, useState} from "react";
import {userService} from "@/services/api.service";
import {useAuth} from "@clerk/clerk-react";

const UserChip = (props: any) => {
    const {UserRef, size = "medium"} = props;
    const {getToken} = useAuth()
    const [isFetching, setFetching] = useState<boolean>(true);
    const [avatar, setAvatar] = useState<string>("");
    const [name, setName] = useState<string>("");


    const fetchData = async () => {
        const token = await getToken();
        if (!token || !UserRef.includes("user_")) return
        const response = await userService.getOne(UserRef, {token});
        if (response && response.data.ok) {
            setAvatar(response?.data.data.imageUrl);
            setName(`${response?.data.data.firstName} ${response?.data.data.lastName}`);
            setFetching(false);
        }
    }

    useEffect(() => {
        if (!UserRef) return
        fetchData();
    }, [UserRef]);

    return (
        <>
            {isFetching ? (
                <Chip
                    icon={!UserRef?.includes("user_") ? <LocalShippingIcon/> : <FaceIcon/>}
                    size={size}
                    label={!UserRef?.includes("user_") ? "Courier" : "Loading..."}
                    // variant="outlined"
                />
            ) : (
                <Chip
                    size={size}
                    avatar={<Avatar alt={name} src={avatar}></Avatar>}
                    label={name}
                    // variant="outlined"
                />
            )}
        </>
    )
}

export default UserChip;
