import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {AppBar, Toolbar} from '@mui/material';

// project import
import HeaderContent from './HeaderContent';

// assets
import React from "react";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({open, handleDrawerToggle}: any) => {
    const theme = useTheme();

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';
    const drawerWidth = 250;

    // common header
    const mainHeader = (
        <Toolbar>
            {/*<IconButton*/}
            {/*	disableRipple*/}
            {/*	aria-label="open drawer"*/}
            {/*	onClick={handleDrawerToggle}*/}
            {/*	edge="start"*/}
            {/*	color="secondary"*/}
            {/*	sx={{color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: {xs: 0, lg: -2}}}*/}
            {/*>*/}
            {/*	{!open ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}*/}
            {/*</IconButton>*/}
            <HeaderContent/>
        </Toolbar>
    );


    return (
        <>
            <AppBar position="fixed" color="inherit" sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                boxShadow: "none",
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
            }}>{mainHeader}</AppBar>
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
