import {Alert, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import React from "react";
import UserChip from "@/components/Common/UserChip";
import {Avatar, Chip} from "@mui/material";
import Button from '@mui/material/Button';
import DeliveryInvoicesList from "@/components/Deliveries/Details/DeliveryInvoicesList";
import DeliveryActivity from "@/components/Deliveries/Details/DeliveryActivity";
import DeliveryMap from "./Details/DeliveryMap";
import DeliveryPortalLink from "./Details/DeliveryPortalLink";

const CustomerField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography variant="body1" color="textSecondary">{title}</Typography>
			<Typography variant="body1">{value || "-"}</Typography>
		</div>
		)
}

const DeliveryDetails = (props: any) => {
	const {data} = props;
	return (
		<Grid container spacing={3}>
			<Grid item xs={9}>
				<DeliveryInvoicesList data={data?.Invoices ? data.Invoices : []}/>
				<br/>
				<Grid container spacing={1}>
					<Grid item xs={8}>
						<Card elevation={0} variant="outlined">
							<CardHeader title="Map and route"/>
							<Divider/>
							<DeliveryMap />
						</Card>
					</Grid>
					<Grid item xs={4}>
						<Card elevation={0} variant="outlined">
							<CardHeader title="Activities"/>
							<Divider/>
							<CardContent>
									<DeliveryActivity DeliveryRef={data?._id}/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<Card elevation={0} variant="outlined">
					<CardHeader title="General information"/>
					<Divider/>
					<CardContent>
						<List>
							<ListItem>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<CustomerField title="Status" value={data?.status}/>
									</Grid>
									<Grid item xs={12} md={6}>
										<CustomerField title="Delivery date" value={data?.deliveryDate ? new Date(data?.deliveryDate).toLocaleDateString() : "-"}/>
									</Grid>
									<Grid item xs={12} md={6}>
										<CustomerField title="Started at" value={data?.deliveryStartedAt ? new Date(data?.deliveryStartedAt).toLocaleTimeString() : "-"}/>
									</Grid>
									<Grid item xs={12} md={6}>
										<CustomerField title="Finished at" value={data?.deliveryFinishedAt ? new Date(data?.deliveryFinishedAt).toLocaleTimeString() : "-"}/>
									</Grid>
									<Grid item xs={12} md={6}>
										<Typography variant="body1" color="textSecondary">Courier</Typography>
                    <Chip
											avatar={<Avatar alt={data?.Courier?.name}></Avatar>}
											label={data?.Courier?.name}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<Typography variant="body1" color="textSecondary">Portal Link</Typography>
										<DeliveryPortalLink DeliveryRef={data?._id}/>
									</Grid>

									<Grid item xs={12} md={12}>
										<CustomerField title="Back office comment" value={data?.comment}/>
									</Grid>
								</Grid>
							</ListItem>
						</List>
					</CardContent>
				</Card>
				<br/>
				<Card elevation={0} variant="outlined">
					<CardHeader title="Metadata"/>
					<Divider/>
					<CardContent>
						<List>
							<ListItem>
								<Grid container spacing={3}>
									<Grid item xs={12} md={12}>
										<CustomerField title="ID" value={data?._id}/>
									</Grid>
									<Grid item xs={12} md={6}>
										<CustomerField title="Created At" value={new Date(data?.createdAt).toLocaleString()}/>
									</Grid>
									<Grid item xs={12} md={6}>
										<CustomerField title="Updated At" value={new Date(data?.updatedAt).toLocaleString()}/>
									</Grid>
									<Grid item xs={12} md={12}>
										<Typography variant="body1" color="textSecondary">Created By</Typography>
										<UserChip UserRef={data?.createdBy}/>
									</Grid>
								</Grid>
							</ListItem>
						</List>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		)
}

export default DeliveryDetails;
