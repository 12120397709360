// material-ui
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';
import SplashImage from '@/assets/images/auth/splash.png';

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
    const theme = useTheme();
    return (
        <Box sx={{
            position: 'absolute',
            zIndex: -1,
            bottom: 0,
            backgroundImage: `url(${SplashImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%'
        }}>
        </Box>
    );
};

export default AuthBackground;
