import HeaderComponent from "@/layouts/MainLayout/components/Header";
import CustomersList from "@/components/Customers/CustomersList";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Grid} from "@mui/material";
import React, { useState } from "react";

const CustomersContainer = () => {
    const [tab, setTab] = useState(0);

    const tabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };


    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sx={{mb: -2.25}}>
                <HeaderComponent title="Customers"/>
            </Grid>

            <Grid item md={8} sx={{display: {sm: 'none', md: 'block', lg: 'none'}}}/>

            {/*<Box sx={{ width: '100%', marginTop: "25px" }}>*/}
            {/*    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
            {/*        <Tabs value={tab} onChange={tabChange} aria-label="test">*/}
            {/*            <Tab label="Demo customers (group 1)"/>*/}
            {/*            <Tab label="Demo customers (group 2)"/>*/}
            {/*        </Tabs>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            <Grid item xs={12} md={12} lg={12}>
                <CustomersList/>
            </Grid>
        </Grid>
    );
}

export default CustomersContainer;
