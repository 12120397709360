import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';


const LoadingComponent: React.FC = () => {

    return (
        <Box sx={{display: 'flex'}}>
            <LinearProgress/>
        </Box>
    );
};

export default LoadingComponent;
