import {Typography} from "@mui/material";
import Breadcrumb from "@/components/Common/Breadcrumbs";

type BreadcrumbLink = {
    title: string,
    href: string
}

interface HeaderProps {
    title: string;
    links?: BreadcrumbLink[];
    hideBreadcrumbs?: boolean;
}


const HeaderComponent = (props: HeaderProps) => {
    const {links = [], title, hideBreadcrumbs = false} = props;
    return (
        <>
            {!hideBreadcrumbs && <Breadcrumb links={links} current={title}/>}
            <Typography variant="h5">{title}</Typography>
        </>
    )
}

export default HeaderComponent;
