import React, {useEffect, useMemo, useReducer} from "react";
import {MaterialReactTable} from 'material-react-table';
import {Avatar, Chip} from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import {useAuth} from "@clerk/clerk-react";
import {couriersService} from "@/services/api.service";


import CouriersDialog from "@/components/Couriers/CouriersDialog";
import {ACTION_TYPES, INITIAL_STATE, reducer, IState} from '@/reducers/commonList'
import { TCourier } from "@/interfaces/types"
import {Box, Button} from "@mui/material";
import ContactDialog from "@/components/CustomerContacts/ContactDialog";



const CustomersList = () => {
    const {getToken, orgId, isLoaded, has} = useAuth();
    const [state, dispatch] = useReducer(reducer<TCourier>, INITIAL_STATE as IState<TCourier>);

    useEffect(() => {
        fetchData()
    }, [state.pagination, orgId])

    const fetchData = async () => {
        const token = await getToken()
        if (!token || !orgId) return
        dispatch({type: ACTION_TYPES.FETCH_START})

        try {
            const response = await couriersService.getList(state.pagination, {token, orgId})
            if (response.data.ok) {
                dispatch({
                    type: ACTION_TYPES.FETCH_SUCCESS, payload: {
                        data: response.data.data.rows,
                        rowCount: response.data.data.rowCount
                    }
                })
            } else {
                dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: response.data.message || "Unknown error"})
            }
        } catch (e) {
            dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: "Unknown error"})
        }
    }

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                Cell: ({cell}: any) => (
                    <Chip
                        avatar={<Avatar alt={cell.getValue()}></Avatar>}
                        label={cell.getValue()}
                    />
                )
            },
            {
                header: 'Phone number',
                accessorKey: 'phoneNumber'
            },
            {
                header: 'Created At',
                accessorKey: 'createdAt',
                Cell: ({cell}: any) => new Date(cell.getValue()).toLocaleString()
            },
            {
                header: 'Updated At',
                accessorKey: 'updatedAt',
                Cell: ({cell}: any) => new Date(cell.getValue()).toLocaleString()
            }
        ],
        [],
    );

    return (
        <div>
            <MaterialReactTable
                initialState={{
                    density: "compact",
                    isLoading: true
                }}
                // onPaginationChange={state.setPagination}
                state={{
                    isLoading: state.isFetching,
                    pagination: state.pagination,
                }}
                manualPagination={true}
                rowCount={state.rowCount}
                pageCount={Math.ceil(state.rowCount / state.pagination.pageSize)}
                // @ts-ignore
                columns={columns}
                data={state.data}
                renderTopToolbarCustomActions={() => (
                    <CouriersDialog onFinish={() => fetchData()}/>
                )}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableTopToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableSorting={false}
                enableRowActions={true}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <CouriersDialog onFinish={fetchData} CourierRef={row.original._id}/>
                    </Box>
                )}
            />
        </div>
    );
}

export default CustomersList;
