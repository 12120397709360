import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField} from "@mui/material";
import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useAuth} from "@clerk/clerk-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import * as yup from 'yup';
import {customerLocationsService} from "@/services/api.service";
import {enqueueSnackbar} from "notistack";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";

const LocationDialog = (props: any) => {
    const {onFinish, CustomerRef, LocationRef = null} = props;

    const {getToken} = useAuth()
    const [open, setOpen] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(!!LocationRef);

    const handleOpenDialog = () => setOpen(true)
    const handleCloseDialog = () => {
        setOpen(false);
        formik.resetForm();
    }

    const validationSchema = yup.object({
        "name": yup.string().required("Name is required"),
        // "email": yup.string().email("Should be valid email"),
//		"phone": yup.mixed().when("phoneNotification", {
//			is: true,
//			then: yup.string().required("Phone is required for notifications"),
//			otherwise: yup.string()
//		})
    });

    const deleteData = async () => {
        if (!LocationRef) return;
        await setFetching(true);
        const token = await getToken();
        if (!token) return
        const response = await customerLocationsService.delete(CustomerRef, LocationRef, {token});
        if (response.data.ok) {
            enqueueSnackbar('Location deleted successfully', {variant: 'success'})
            handleCloseDialog();
            onFinish();
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const fetchData = async () => {
        // Fetch data from api (customerContactsService.getOne) and set to formik
        const token = await getToken();
        if (!token) return
        await setFetching(true);
        const response = await customerLocationsService.getOne(CustomerRef, LocationRef, {token});
        setFetching(false);
        if (response.data.ok) {
            await formik.setValues(response.data.data);
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const submitData = async (payload: any) => {
        await setFetching(true);
        const token = await getToken();
        if (!token) return

        if (LocationRef) {
            const response = await customerLocationsService.update(CustomerRef, LocationRef, payload, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Location updated successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
            return;
        } else {
            const response = await customerLocationsService.create(CustomerRef, payload, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Location created successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
        }
    }


    const formik = useFormik({
        initialValues: {
            name: "",
            country: "IL",
            city: "",
            zipCode: "",
            street: "",
            houseNumber: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await submitData(values);
            // const response = customerService.create()
            // alert(JSON.stringify(values, null, 2));
        },
    });


    useEffect(() => {
        if (!open || !LocationRef) return;
        fetchData();
    }, [open])
    return (
        <>
            <IconButton aria-label="settings" onClick={handleOpenDialog}>
                {LocationRef ? <EditIcon/> : <AddBoxIcon/>}
            </IconButton>
            <Dialog open={open} onClose={handleCloseDialog} fullWidth={true}>
                <DialogTitle>{LocationRef ? "Edit location" : "Create location"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    value={formik.values["name"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["name"] && Boolean(formik.errors["name"])}
                                    helperText={formik.touched["name"] && formik.errors["name"]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    name="country"
                                    label="Country"
                                    variant="filled"
                                    value={formik.values["country"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["country"] && Boolean(formik.errors["country"])}
                                    helperText={formik.touched["country"] && formik.errors["country"]}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="city"
                                    label="City"
                                    variant="filled"
                                    value={formik.values["city"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["city"] && Boolean(formik.errors["city"])}
                                    helperText={formik.touched["city"] && formik.errors["city"]}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="street"
                                    label="Street"
                                    variant="filled"
                                    value={formik.values["street"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["street"] && Boolean(formik.errors["street"])}
                                    helperText={formik.touched["street"] && formik.errors["street"]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="houseNumber"
                                    label="House number"
                                    variant="filled"
                                    value={formik.values["houseNumber"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["houseNumber"] && Boolean(formik.errors["houseNumber"])}
                                    helperText={formik.touched["houseNumber"] && formik.errors["houseNumber"]}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    {LocationRef && (
                        <ConfirmationDialog
                            message="Are you sure you want to delete this location?"
                            confirmButton="Yes, delete"
                            cancelButton="Cancel"
                            onConfirm={deleteData}
                        >
                            <Button disabled={isFetching} variant="outlined" color="error">Delete</Button>
                        </ConfirmationDialog>
                    )}
                    <Box flexGrow={1}/>
                    <Button onClick={handleCloseDialog} disabled={isFetching}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained" disabled={isFetching}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LocationDialog;
