import HeaderComponent from "@/layouts/MainLayout/components/Header";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Grid} from "@mui/material";
import DeliveriesList from "@/components/Deliveries/DeliveriesList";
import {Typography} from "@mui/material";
import React, { useState } from "react";

const DeliveriesContainer = () => {
	const [tab, setTab] = useState(0);

	const tabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<Grid container rowSpacing={4.5} columnSpacing={2.75}>
			<Grid item xs={12} sx={{mb: -2.25}}>
				<HeaderComponent title="Deliveries"/>
			</Grid>

			<Grid item md={8} sx={{display: {sm: 'none', md: 'block', lg: 'none'}}}/>
			{/*<Box sx={{ width: '100%', marginTop: "25px" }}>*/}
			{/*	<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
			{/*		<Tabs value={tab} onChange={tabChange} aria-label="test">*/}
			{/*			<Tab label="Today"/>*/}
			{/*			<Tab label="Tomorrow" />*/}
			{/*			<Tab label="This week" />*/}
			{/*			<Tab label="Other" />*/}
			{/*		</Tabs>*/}
			{/*	</Box>*/}
			{/*</Box>*/}
			<Grid item xs={12} md={12} lg={12}>
				<DeliveriesList/>
			</Grid>
		</Grid>
		);
}

export default DeliveriesContainer;
