import {useParams} from 'react-router-dom';
import HeaderComponent from "@/layouts/MainLayout/components/Header";
import {Alert, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAuth} from "@clerk/clerk-react";
import {deliveriesService} from "@/services/api.service";
import LinearProgress from "@mui/material/LinearProgress";
import DeliveryDetails from "@/components/Deliveries/DeliveryDetails";

const DeliveryContainer = () => {
    const [isFetching, setFetching] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [data, setData] = useState<any | null>(null); // TODO: define customer type
    const {getToken, orgId} = useAuth();
    const {ref, action = "view"} = useParams();

    useEffect(() => {
        fetchData()
    }, [ref]);

    const fetchData = async () => {
        const token = await getToken();
        if (!ref || !token || !orgId) return;
        try {
            const response = await deliveriesService.getOne(ref, {token, orgId});
            if (response.data.ok) {
                setData(response.data.data);
                setErrorMessage(null)
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (e) {
            setErrorMessage("Something went wrong");
        } finally {
            setFetching(false);
        }
    }

    return (
        <div>
            <HeaderComponent title={data ? `Delivery` : "Loading"} links={[
                {title: "Deliveries", href: "/deliveries"},
            ]}/>
            {isFetching && <LinearProgress/>}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Grid container>
                <Grid item xs={12}>
                    <DeliveryDetails data={data} />
                </Grid>
            </Grid>
        </div>
    );
}

export default DeliveryContainer;
