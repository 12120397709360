import {Box, Button, Card, CardContent, CardHeader, Divider, IconButton, Tooltip, Checkbox} from "@mui/material";
import React, {useEffect, useMemo, useReducer} from 'react';
import {customerLocationsService} from "@/services/api.service";
import {useAuth} from "@clerk/clerk-react";
import {MaterialReactTable} from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import LocationDialog from "./LocationDialog";

import {ACTION_TYPES, INITIAL_STATE, reducer} from '@/reducers/customerContacts'
import {enqueueSnackbar} from "notistack";

const CustomerCardLocations = (props: any) => {
    const {customerId} = props;

    const {getToken, orgId} = useAuth();

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

    useEffect(() => {
        fetchData()
    }, [customerId, state.pagination, orgId])

    const fetchData = async () => {
        const token = await getToken()
        if (!token || !orgId || !customerId) return
        dispatch({type: ACTION_TYPES.FETCH_START})

        try {
            const response = await customerLocationsService.getList(customerId, state.pagination, {token, orgId})
            if (response.data.ok) {
                dispatch({
                    type: ACTION_TYPES.FETCH_SUCCESS, payload: {
                        data: response.data.data.rows,
                        rowCount: response.data.data.rowCount
                    }
                })
            } else {
                dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: response.data.message || "Unknown error"})
            }
        } catch (e) {
            dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: "Unknown error"})
        }
    }

    const columns = useMemo(() => [
        {
            header: 'Name',
            accessorKey: 'name',
        },
        {
            header: 'City',
            accessorKey: 'city',
        },
        {
            header: 'Street',
            accessorKey: 'street',
        }
    ], []);

    return (
        <Card elevation={0} variant="outlined">
            <CardHeader
                title="Locations"
                //				subheader="September 14, 2016"
                action={
                    <LocationDialog onFinish={fetchData} CustomerRef={customerId}/>
                }
            />
            <Divider/>
            <CardContent style={{padding: "0"}}>
                <MaterialReactTable
                    initialState={{
                        density: "compact",
                        isLoading: true
                    }}
                    //            onPaginationChange={setPagination}
                    state={{
                        isLoading: state.isFetching,
                        pagination: state.pagination,
                    }}
                    manualPagination={true}
                    rowCount={state.rowCount}
                    pageCount={Math.ceil(state.rowCount / state.pagination.pageSize)}
                    // @ts-ignore
                    columns={columns}
                    data={state.data}
                    enableTopToolbar={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableSorting={false}
                    enableRowActions={true}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableEditing
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <LocationDialog onFinish={fetchData} CustomerRef={customerId} LocationRef={row.original._id}/>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <Button
                            color="secondary"
                            //                    onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                        >
                            Create New Account
                        </Button>
                    )}
                />
            </CardContent>
        </Card>
    )
}

export default CustomerCardLocations;
