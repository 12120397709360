import { useState } from "react";
import {enqueueSnackbar} from "notistack";
import {useAuth} from "@clerk/clerk-react";
import Button from '@mui/material/Button';

import {deliveriesService} from "@/services/api.service";


const DeliveryPortalLink = (props: any) => {
	const { DeliveryRef = false } = props;

	const [isFetching, setFetching] = useState(false);
	const { getToken, orgId } = useAuth();

	const fetchData = async () => {
		const token = await getToken();
		if(!token || !orgId || !DeliveryRef) return
		setFetching(true);

		try{
			const response = await deliveriesService.sendLink(DeliveryRef, { token, orgId });
			setFetching(false);
			if(response.data.ok) {
				enqueueSnackbar('SMS has been sent successfully', {variant: 'success'})
				setFetching(false);
			} else {
				enqueueSnackbar('Send failed', {variant: 'error'})
				console.error(response.data.message);
			}
		}catch(e){
			console.error(e);
			enqueueSnackbar('Send failed', {variant: 'error'})
			setFetching(false);
		}
	}
	return (
		<Button variant="contained" color="primary" size={"small"} disabled={isFetching} onClick={fetchData}>{isFetching ? "Sending..." : "Send SMS"}</Button>
	)
}

export default DeliveryPortalLink;