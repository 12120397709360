import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import React from "react";

type DeliveryMapProps = {
	addresses?: any;
}

const containerStyle = {
	width: '100%',
	height: '400px'
};

const center = {
	lat: 32.0983,
	lng: 34.7818
};

const DeliveryMap = (props: DeliveryMapProps) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyBIYYWAjFP-RiETweoVsfzcQDEgMi3Un6E"
	})
	const [map, setMap] = React.useState(null)
//	const onLoad = React.useCallback(function callback(map) {
//		// This is just an example of getting and using the map instance!!! don't just blindly copy!
//		const bounds = new window.google.maps.LatLngBounds(center);
//		map.fitBounds(bounds);
//
//		setMap(map)
//	}, [])
//	const onUnmount = React.useCallback(function callback(map) {
//		setMap(null)
//	}, [])

	const onLoad = () => {}
	const onUnmount = () => {}
	
	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={11}
			onLoad={onLoad}
			onUnmount={onUnmount}
		>
			{ /* Child components, such as markers, info windows, etc. */ }
			<Marker
				position={{	lat: 32.0983, lng: 34.7818 }}
				title={"Shop #1"}
			/>
			<Marker
				position={{	lat: 32.0943, lng: 34.8818 }}
				title={"Shop #2"}
			/>
			<Marker
				position={{	lat: 32.0043, lng: 34.8818 }}
				title={"Shop #3"}
			/>
		</GoogleMap>
		) : <></>
}

export default DeliveryMap;