// material-ui
import {Chip, Typography} from '@mui/material';
import {UserButton, useAuth } from "@clerk/clerk-react";
import * as React from "react";

// project import

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const { orgId, userId } = useAuth();
    return (
        <>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                <Chip label="Version: dev/0.0.1"/>
                &nbsp;
                &nbsp;
                <Chip label={`${orgId} / ${userId}`}/>
            </Typography>
            <UserButton
                showName={true}
                userProfileUrl="/settings/account"
                userProfileMode="navigation"
            />
        </>
    );
};

export default HeaderContent;
