import React, { useEffect, useReducer } from "react";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';

import {useAuth} from "@clerk/clerk-react";
import {deliveriesService} from "@/services/api.service";
import {ACTION_TYPES, INITIAL_STATE, reducer, IState} from '@/reducers/simpleList'
import { TActivity } from "@/interfaces/types";
import UserChip from "@/components/Common/UserChip";


const ActivityItem = (props: { activity: TActivity, isLast: boolean }) => {
	const { activity, isLast } = props;
	
	return (
		<TimelineItem>
			<TimelineOppositeContent>
				<UserChip UserRef={activity.createdBy} size="small"/>

			</TimelineOppositeContent>
			<TimelineSeparator>
				<TimelineDot />
				{!isLast && <TimelineConnector />}
			</TimelineSeparator>
			<TimelineContent>
				{activity.eventType.split(".").join(" ")}<br/>
				<Typography color="secondary">{new Date(activity.createdAt).toLocaleString()}</Typography>
			</TimelineContent>
		</TimelineItem>
		)
}


const DeliveryActivity = (props: any) => {
	const { DeliveryRef = false } = props;

	const { getToken, orgId } = useAuth();
	const [ state, dispatch ] = useReducer(reducer<TActivity>, INITIAL_STATE as IState<TActivity>);

	useEffect(() => {
		fetchData()
		let interval = setInterval(() => fetchData(), 5*1000)
		return () => {
			clearInterval(interval)
		}
	}, [DeliveryRef, orgId])

	const fetchData = async () => {
		const token = await getToken();
		if(!token || !orgId || !DeliveryRef) return
		dispatch({ type: ACTION_TYPES.FETCH_START })

		try{
			const response = await deliveriesService.getActivities(DeliveryRef, { token, orgId });
			if(response.data.ok) {
				dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: response.data.data.rows })
			} else {
				dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: response.data.message || "Unknown error"})
			}
		}catch(e){
			dispatch({type: ACTION_TYPES.FETCH_ERROR, payload: "Unknown error"})
		}
	}

	return (
		<Timeline>
			{state.data.map((activity, index) => <ActivityItem activity={activity} key={`activity-${index}`} isLast={state.data.length==index+1} />)}
		</Timeline>
		)
}

export default DeliveryActivity;