const NotFoundPage = () => {
    return (
        <div>
            <h1>Error</h1>
            <h3>Page not found</h3>
        </div>
    )
}

export default NotFoundPage;
