export enum ACTION_TYPES {
    FETCH_START = 'FETCH_START',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_ERROR = 'FETCH_ERROR'
}

export interface IState<T> {
    isFetching: boolean;
    errorMessage: string;
    data: T[];
}

export const INITIAL_STATE: IState<any> = {
    isFetching: false,
    errorMessage: "",
    data: [],
};

interface FetchStartAction {
    type: ACTION_TYPES.FETCH_START;
}

interface FetchSuccessAction<T> {
    type: ACTION_TYPES.FETCH_SUCCESS;
    payload: T[];
}

interface FetchErrorAction {
    type: ACTION_TYPES.FETCH_ERROR;
    payload: string;
}

type ActionTypes<T> = FetchStartAction | FetchSuccessAction<T> | FetchErrorAction;

export function reducer<T>(state: IState<T>, action: ActionTypes<T>): IState<T> {
    switch (action.type) {
        case ACTION_TYPES.FETCH_START:
            return {
                ...state,
                isFetching: true,
                errorMessage: "",
            };
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorMessage: "",
                data: action.payload,
            };
        case ACTION_TYPES.FETCH_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
                data: [],
            };
        default:
            return state;
    }
}
