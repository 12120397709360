import PropTypes from 'prop-types';

// material-ui
import {Box, Chip, Grid, Stack, Typography} from '@mui/material';

// project import
import MainCard from '../MainCard';

// assets
import {FallOutlined, RiseOutlined} from '@ant-design/icons';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const StatCard = ({color, title, count, percentage, isLoss, extra, description}: any) => (
    <MainCard contentSX={{p: 2.25}}>
        <Stack spacing={0.5}>
            <Typography variant="h6" color="textSecondary">
                {title}
            </Typography>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="inherit">
                        {count}
                    </Typography>
                </Grid>
                {(!isNaN(percentage)) && (
                    <Grid item>
                        <Chip
                            // variant="combined"
                            color={color}
                            icon={
                                <>
                                    {!isLoss && <RiseOutlined style={{fontSize: '0.75rem', color: 'inherit'}}/>}
                                    {isLoss && <FallOutlined style={{fontSize: '0.75rem', color: 'inherit'}}/>}
                                </>
                            }
                            label={`${percentage}%`}
                            sx={{ml: 1.25, pl: 1}}
                            size="small"
                        />
                    </Grid>
                )}
            </Grid>
        </Stack>
        {extra && (
            <Box sx={{pt: 2.25}}>
                <Typography variant="caption" color="textSecondary">
                    You made an extra{' '}
                    <Typography component="span" variant="caption" sx={{color: `${color || 'primary'}.main`}}>
                        {extra}
                    </Typography>{' '}
                    this {new Date().toLocaleString('en-us', {month: 'long'})}
                </Typography>
            </Box>
        )}
        {description && (
            <Box sx={{pt: 2.25}}>
                <Typography variant="caption" color="textSecondary">
                    {description}
                </Typography>
            </Box>
        )}
    </MainCard>
);

StatCard.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.number,
    percentage: PropTypes.number,
    description: PropTypes.string,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

StatCard.defaultProps = {
    color: 'primary'
};

export default StatCard;
