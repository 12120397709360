import { useState, useEffect } from 'react';
import {useAuth} from "@clerk/clerk-react";
import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DocumentViewer from "@/components/Documents/DocumentViewer";
import { invoicesService } from "@/services/api.service";
import {enqueueSnackbar} from "notistack";

const DocumentViewerDialog = (props: any) => {
	const { InvoiceRef } = props
	
	const {getToken} = useAuth()
	const [open, setOpen] = useState<boolean>(false);
	const [isFetching, setFetching] = useState<boolean>(false);
	const [presignedUrl, setPresignedUrl] = useState<string>("");
	
	const handleOpenDialog = () => setOpen(true)
	const handleCloseDialog = () => 	setOpen(false);
	
	useEffect(() => {
		if (!open || !InvoiceRef) return;
		fetchData();
		}, [open])
	
	const fetchData = async () => {
		// Fetch data from api (customerContactsService.getOne) and set to formik
		const token = await getToken();
		if (!token) return
		setFetching(true);
		const response = await invoicesService.getLink(InvoiceRef, {token});
		if (response.data.ok) {
			console.log(InvoiceRef)
			console.log(response.data);
			setPresignedUrl(response.data.data);
			setFetching(false);
		} else {
			enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
		}
	}
	
	if(!InvoiceRef) return;
	
	return (
		<>
			<IconButton aria-label="settings" onClick={handleOpenDialog}>
				<PictureAsPdfIcon/>
			</IconButton>
			<Dialog open={open} onClose={handleCloseDialog} fullWidth={true}>
				<DialogTitle>Invoice preview</DialogTitle>
				<DialogContent>
					{presignedUrl&&!isFetching&&<DocumentViewer fileUrl={presignedUrl}/>}
					{isFetching&&<>Loading...</>}
				</DialogContent>
				<DialogActions>
					<Box flexGrow={1}/>
					<Button onClick={handleCloseDialog}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}


export default DocumentViewerDialog;