// project import
import pages from './pages';
import dashboard from './dashboard';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
	items: [dashboard, pages, settings]
};

export default menuItems;
