import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Alert} from "@mui/material";

const ConfirmationDialog = (props: any) => {
    const {
        onConfirm = () => {
        },
        onCancel = () => {
        },
        title = "Confirmation",
        message = "Are you sure?",
        confirmButton = "Confirm",
        cancelButton = "Cancel",
        severity = "error"
    } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleConfirm = () => {
        setOpen(false);
        onConfirm();
    }

    const handleCancel = () => {
        setOpen(false);
        onCancel();
    }

    return (
        <>
            {props.children && React.cloneElement(props.children, {
                onClick: () => {
                    setOpen(true)
                }
            })}
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Alert severity={severity}>{message}</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">{cancelButton}</Button>
                    {severity === "error" && <Button onClick={handleConfirm} color="error" variant="contained">{confirmButton}</Button>}
                </DialogActions>
            </Dialog>
        </>
    )

}

export default ConfirmationDialog;
