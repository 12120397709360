export enum ACTION_TYPES {
    FETCH_START = 'FETCH_START',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_ERROR = 'FETCH_ERROR'
}

export interface State<T> {
    isFetching: boolean;
    errorMessage: string;
    data: T[];
    pagination: {
        pageIndex: number,
        pageSize: number,
    },
    rowCount: number
}

export const INITIAL_STATE = {
    isFetching: false,
    errorMessage: "",
    data: [],
    pagination: {
        pageIndex: 0,
        pageSize: 5
    },
    rowCount: 0
};


/* Interfaces */
interface FetchStartAction {
    type: ACTION_TYPES.FETCH_START;
//	payload: {
//		pagination: {
//			pageIndex: number,
//			pageSize: number
//		},
//	}
}

interface FetchSuccessAction {
    type: ACTION_TYPES.FETCH_SUCCESS;
    payload: {
        data: any[],
        rowCount: number
    }
}

interface FetchErrorAction {
    type: ACTION_TYPES.FETCH_ERROR;
    payload: string;
}

type ActionTypes = FetchStartAction | FetchSuccessAction | FetchErrorAction;


export const reducer = (state: State<any> = INITIAL_STATE, action: ActionTypes): State<any> => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_START:
            return {
                ...state,
                isFetching: true,
                errorMessage: "",
//				pagination: {
//					pageIndex: action.payload.pagination.pageIndex,
//					pageSize: action.payload.pagination.pageSize
//				}
            };
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorMessage: "",
                data: action.payload.data,
                rowCount: action.payload.rowCount
            };
        case ACTION_TYPES.FETCH_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
                data: [],
                rowCount: 0
            };
        default:
            return state;
    }
};
