import React, {useEffect, useState} from "react";
import {useFormik} from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, IconButton, InputAdornment, Typography} from "@mui/material";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {customerService} from "@/services/api.service";
import {useAuth} from "@clerk/clerk-react";
import {enqueueSnackbar} from "notistack";
import Divider from "@mui/material/Divider";

const CustomerCreateDialog = (params: { onCustomerCreated: any; }) => {
    const {onCustomerCreated} = params
    const [open, setOpen] = useState(false);
    const {getToken} = useAuth()
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
    }

    const [isFetching, setFetching] = useState(false);


    const validationSchema = yup.object({
        "companyNumber": yup
            .string()
            .required("Company number is required")
            .test('len', 'Must be exactly 9 numbers', val => val.length === 9),
        "title.en": yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        "title.he": yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            companyNumber: '',
            "title.en": '',
            "title.he": '',
            description: ''
        },
        // validationSchema: validationSchema,
        onSubmit: async (values) => {
            await fetchData(values);
            // const response = customerService.create()
            // alert(JSON.stringify(values, null, 2));
        },
    });

    const fetchData = async (payload: any) => {
        await setFetching(true);
        const token = await getToken();
        if (!token) return
        const response = await customerService.create(payload, {token});
        await setFetching(false);
        if (response.data.ok) {
            enqueueSnackbar('Customer created successfully', {variant: 'success'})
            handleClose();
            onCustomerCreated();
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }

    }


    const ifScanFeatureAvailable = !formik.values["title.en"] && formik.values["companyNumber"] && formik.values["companyNumber"].length === 9 && parseInt(formik.values["companyNumber"].substring(0, 2)) >= 51 && parseInt(formik.values["companyNumber"].substring(0, 2)) <= 59

    const scanCompanyNumber = async () => {
        await setFetching(true);
        const token = await getToken();
        if (!token) return
        const response = await customerService.scan({
            companyNumber: formik.values.companyNumber
        }, {token});
        await formik.setFieldValue("['title.en']", response.data?.data?.title.en);
        await formik.setFieldValue("['title.he']", response.data?.data?.title.he);
        await formik.setFieldValue('description', "Information found automatically");
        await formik.setFieldValue('contactName', "Main contact");
        await formik.setFieldValue('contactEmail', response.data?.data?.contactEmail);
        await formik.setFieldValue('contactPhone', response.data?.data?.contactPhone);
        await setFetching(false);
        if (response.data.ok) {
            enqueueSnackbar('We found information about the company!', {variant: 'success'})
        } else {
            enqueueSnackbar('We cannot find the information about that company', {variant: 'error'})
        }
    }

    useEffect(() => {
    }, [formik.values])
    return (
        <>
            <Button
                color="secondary"
                onClick={handleClickOpen}
                variant="contained"
            >
                Create New Customer
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>Create new Customer</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="companyNumber"
                                    label="Company number"
                                    variant="filled"
                                    value={formik.values["companyNumber"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["companyNumber"] && Boolean(formik.errors["companyNumber"])}
                                    helperText={formik.touched["companyNumber"] && formik.errors["companyNumber"]}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                disabled={isFetching || !ifScanFeatureAvailable}
                                                aria-label="toggle password visibility"
                                                onClick={scanCompanyNumber}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                <TravelExploreIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="['title.en']"
                                    label="English Title"
                                    variant="filled"
                                    value={formik.values["title.en"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["title.en"] && Boolean(formik.errors["title.en"])}
                                    helperText={formik.touched["title.en"] && formik.errors["title.en"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="['title.he']"
                                    label="Hebrew Title"
                                    variant="filled"
                                    value={formik.values["title.he"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["title.he"] && Boolean(formik.errors["title.he"])}
                                    helperText={formik.touched["title.he"] && formik.errors["title.he"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="description"
                                    label="Description"
                                    variant="filled"
                                    value={formik.values["description"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["description"] && Boolean(formik.errors["description"])}
                                    helperText={formik.touched["description"] && formik.errors["description"]}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isFetching}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained" disabled={isFetching}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomerCreateDialog;
