import {useEffect, useState} from "react";
import {useAuth} from "@clerk/clerk-react";
import {enqueueSnackbar} from "notistack";

import StatCard from "@/components/Common/StatCard";
import {customerService} from "@/services/api.service";

const TotalCustomersCard = () => {
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [percentage, setPercentage] = useState(0.00);
    const [extra, setExtra] = useState(0);
    const [isError, setError] = useState<boolean>(false);

    const [isFetching, setFetching] = useState(true);
    const {getToken} = useAuth();

    const fetchData = async () => {
        const token: any = await getToken();
        try{
            const response = await customerService.stats({token})
            setTotalCustomers(response.data.data.totalCustomers);
            setExtra(response.data.data.newCustomers);
            setPercentage((response.data.data.newCustomers / response.data.data.totalCustomers) * 100);
            setFetching(false);
        }catch(e){
            if(e instanceof Error) {
                enqueueSnackbar('Error: ' + e.message || "Unknown message", {variant: 'error'})
            }else{
                enqueueSnackbar('Unknown error'|| "Unknown message", {variant: 'error'})
            }
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(isError) return <></>
    
    return (
        <StatCard title="Total customers" count={totalCustomers} percentage={percentage} extra={extra}/>
    )
}

export default TotalCustomersCard;
