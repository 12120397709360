import {useState} from "react";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import {IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useAuth} from "@clerk/clerk-react";
import {customerService} from "@/services/api.service";
import {enqueueSnackbar} from "notistack";

const CustomerDeleteDialog = (props: any) => {
    const {customer} = props;
    const navigate = useNavigate()
    const {getToken} = useAuth()
    const [isFetching, setFetching] = useState<boolean>(false)


    const deleteData = async () => {
        const token = await getToken();
        if (!token || !customer._id) return
        await setFetching(true);
        const response = await customerService.delete(customer._id, {token});
        if (response.data.ok) {
            navigate("/customers")
            enqueueSnackbar('Customer deleted successfully', {variant: 'success'})
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }

    if (!customer) return <></>

    return (
        <ConfirmationDialog
            title="Delete customer confirmation"
            message={`Are you sure you want to delete customer ${customer?.title?.en}? We also will delete all related data: Contacts, Invoices and other entities. You will not be able to undo this action. Our support team will not be able to restore your data.`}
            confirmButton={"Yes, delete customer"}
            onConfirm={deleteData}
        >
            <IconButton aria-label="settings" color="error">
                <DeleteForeverIcon/>
            </IconButton>
        </ConfirmationDialog>
    )
}

export default CustomerDeleteDialog;
