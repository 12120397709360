import {lazy} from 'react';
import MainLayout from "@/layouts/MainLayout";
import AuthGuard from "@/routes/AuthGuard";

import HomePage from "@/containers/Home/Home";
import AccountContainer from "@/containers/Account";
import TenantContainer from "@/containers/Tenant";


import CustomersContainer from '@/containers/Customers/Customers';
import CustomerContainer from "@/containers/Customers/Customer";
import CouriersContainer from '@/containers/Couriers/Couriers';
import DocumentsContainer from "@/containers/Documents/Documents";
import DeliveriesContainer from "@/containers/Deliveries/Deliveries";
import DeliveryContainer from "@/containers/Deliveries/Delivery";


import IntegrationsContainer from "@/containers/Integrations";


import ChangelogContainer from "@/containers/Changelog";


//const CustomersContainer = lazy(() => import('@/containers/Customers/Customers'));
//const CustomerContainer = lazy(() => import("@/containers/Customers/Customer"));
//const CouriersContainer = lazy(() => import('@/containers/Couriers/Couriers'));
//const DocumentsContainer = lazy(() => import("@/containers/Documents/Documents"));
//const DeliveriesContainer = lazy(() => import("@/containers/Deliveries/Deliveries"));
//const DeliveryContainer = lazy(() => import("@/containers/Deliveries/Delivery"));

const MainRoutes = {
    path: "/",
    element: <AuthGuard><MainLayout/></AuthGuard>,
    children: [
        {path: "/", element: <HomePage/>},
        {path: "/customers", element: <CustomersContainer/>},
        {path: "/customers/:uuid/:action", element: <CustomerContainer/>},
        {path: "/documents", element: <DocumentsContainer/>},
        {path: "/settings/couriers", element: <CouriersContainer/>},
        {path: "/deliveries", element: <DeliveriesContainer/>},
        {path: "/deliveries/:ref/:action", element: <DeliveryContainer/>},
        {path: "/settings/account", element: <AccountContainer/>},
        {path: "/settings/tenant", element: <TenantContainer/>},
        {path: "/settings/integrations", element: <IntegrationsContainer/>},
        {path: "/changelog", element: <ChangelogContainer/> }
    ]
}
export default MainRoutes;
