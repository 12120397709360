import {Outlet} from 'react-router-dom';
import {Suspense} from "react";
import {Box, Toolbar} from '@mui/material';

// project import
import Sidebar from './Sidebar';
import Header from './Header';
import LoadingComponent from "@/components/Common/LoadingComponent";

const MainLayout = () => {
    return (
        <Box sx={{display: 'flex', width: '100%'}}>
            <Sidebar/>
            <Box component="main" sx={{width: '100%', flexGrow: 1, p: {xs: 2, sm: 3}}}>
                <Header open={true}/>
                <Toolbar/>
                <main>
                    <Suspense fallback={<LoadingComponent/>}>
                        <Outlet/>
                    </Suspense>
                </main>
            </Box>
        </Box>
    );
};

export default MainLayout;
