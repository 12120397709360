import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField} from "@mui/material";
import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useAuth} from "@clerk/clerk-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import * as yup from 'yup';
import {couriersService} from "@/services/api.service";
import {enqueueSnackbar} from "notistack";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";

const CouriersDialog = (props: any) => {
    const {onFinish, CourierRef = null} = props;

    const {getToken, has} = useAuth()
    const [open, setOpen] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(!!CourierRef);


    // Permissions
    const PERMISSIONS = {
        EDIT: true, // has({ permission: "org:couriers:edit" }) || false,
        CREATE: true // has({ permission: "org:couriers:create" }) || false
    }


    const handleOpenDialog = () => setOpen(true)
    const handleCloseDialog = () => {
        setOpen(false);
        formik.resetForm();
    }

    const validationSchema = yup.object({
        "name": yup.string().required("Name is required"),
        "phoneNumber": yup.string().required("Phone number is required")
    });

    const deleteData = async () => {
        if (!CourierRef) return;
        await setFetching(true);
        const token = await getToken();
        if (!token) return
        const response = await couriersService.delete(CourierRef, {token});
        if (response.data.ok) {
            enqueueSnackbar('Courier deleted successfully', {variant: 'success'})
            handleCloseDialog();
            onFinish();
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const fetchData = async () => {
        // Fetch data from api (customerContactsService.getOne) and set to formik
        const token = await getToken();
        if (!token) return
        await setFetching(true);
        const response = await couriersService.getOne(CourierRef, {token});
        setFetching(false);
        if (response.data.ok) {
            await formik.setValues(response.data.data);
        } else {
            enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
        }
    }
    const submitData = async (payload: any) => {
        await setFetching(true);
        const token = await getToken();
        if (!token) return

        if (CourierRef) {
            const response = await couriersService.update(CourierRef, payload, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Courier updated successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
            return;
        } else {
            const response = await couriersService.create(payload, {token});
            await setFetching(false);
            if (response.data.ok) {
                enqueueSnackbar('Courier created successfully', {variant: 'success'})
                handleCloseDialog();
                onFinish();
            } else {
                enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
            }
        }
    }


    const formik = useFormik({
        initialValues: {
            name: "",
            phoneNumber: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await submitData(values);
            // const response = customerService.create()
            // alert(JSON.stringify(values, null, 2));
        },
    });


    useEffect(() => {
        if (!open || !CourierRef) return;
        fetchData();
    }, [open])
    return (
        <>
        <IconButton id={`courier-edit-${CourierRef}`} onClick={handleOpenDialog} disabled={CourierRef ? !PERMISSIONS.EDIT : !PERMISSIONS.CREATE}>
                {CourierRef ? <EditIcon/> : <AddBoxIcon/>}
            </IconButton>
            <Dialog open={open} onClose={handleCloseDialog} fullWidth={true}>
                <DialogTitle>{CourierRef ? "Edit courier" : "Create courier"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    value={formik.values["name"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["name"] && Boolean(formik.errors["name"])}
                                    helperText={formik.touched["name"] && formik.errors["name"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isFetching}
                                    fullWidth
                                    name="phoneNumber"
                                    label="Phone number"
                                    variant="filled"
                                    value={formik.values["phoneNumber"]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["phoneNumber"] && Boolean(formik.errors["phoneNumber"])}
                                    helperText={formik.touched["phoneNumber"] && formik.errors["phoneNumber"]}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    {CourierRef && (
                        <ConfirmationDialog
                            message="Are you sure you want to delete this courier?"
                            confirmButton="Yes, delete"
                            cancelButton="Cancel"
                            onConfirm={deleteData}
                        >
                            <Button disabled={isFetching} variant="outlined" color="error">Delete</Button>
                        </ConfirmationDialog>
                    )}
                    <Box flexGrow={1}/>
                    <Button onClick={handleCloseDialog} disabled={isFetching}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained" disabled={isFetching}>
                        {CourierRef ? "Save" : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CouriersDialog;
