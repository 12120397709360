// assets
import {
	TruckOutlined,
	AppstoreOutlined
} from '@ant-design/icons';

// icons
const icons = {
	TruckOutlined,
	AppstoreOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
	id: 'settings',
	title: 'Settings',
	type: 'group',
	children: [
		{
			title: 'Couriers',
			type: 'item',
			url: '/settings/couriers',
			icon: icons.TruckOutlined
		},
		{
			title: 'Integrations',
			type: 'item',
			url: '/settings/integrations',
			icon: icons.AppstoreOutlined
		},
	]
};

export default utilities;
