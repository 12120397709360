import AuthWrapper from "@/containers/Auth/AuthWrapper";

import {SignUp} from '@clerk/clerk-react';

const AuthSignupPage = () => {
    return (
        <AuthWrapper>
            <SignUp signInUrl={"/auth/login"}/>
        </AuthWrapper>
    );
}

export default AuthSignupPage;
