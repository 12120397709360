import {useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {useAuth} from "@clerk/clerk-react";

import StatCard from "@/components/Common/StatCard";
import {invoicesService} from "@/services/api.service";


const TotalInvoicesCard = () => {
    const [totalEntities, setTotalEntities] = useState(0);
    const [percentage, setPercentage] = useState(0.00);
    const [extra, setExtra] = useState(0);
    const [isError, setError] = useState<boolean>(false);

    const [isFetching, setFetching] = useState(true);
    const {getToken} = useAuth();

    const fetchData = async () => {
        const token: any = await getToken();
        try{
            const response = await invoicesService.stats({token})
            setTotalEntities(response.data.data.total);
            setExtra(response.data.data.new);
            setPercentage((response.data.data.new / response.data.data.total) * 100);
            setFetching(false);
        }catch(e){
            if(e instanceof Error) {
                enqueueSnackbar('Error: ' + e.message || "Unknown message", {variant: 'error'})
            }else{
                enqueueSnackbar('Unknown error'|| "Unknown message", {variant: 'error'})
            }
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(isError) return <></>

    return (
        <StatCard title="Total invoices" count={totalEntities} percentage={percentage} extra={extra}/>
    )
}

export default TotalInvoicesCard;
