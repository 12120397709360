import {OrganizationProfile} from "@clerk/clerk-react";
import {Grid} from "@mui/material";
import HeaderComponent from "@/layouts/MainLayout/components/Header";

const TenantContainer = () => {


    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sx={{mb: -2.25}}>
                <HeaderComponent title="Organization"/>
            </Grid>
            <Grid item md={8} sx={{display: {sm: 'none', md: 'block', lg: 'none'}}}/>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <OrganizationProfile/>
            </Grid>
        </Grid>
    );
}

export default TenantContainer;
