import { ReactElement } from 'react';
// material-ui
import {Box, Typography} from '@mui/material';

// project import
// @ts-ignore
import NavGroup from './NavGroup';
// @ts-ignore
import menuItem from './menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

interface NavItem {
	id?: string;
	title: string;
	type: string;
	url: string;
	icon: ReactElement; // Specify that icon is a React component
}

const Navigation = () => {
	const navGroups = menuItem.items.map((item: NavItem) => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={`item-${item.id}`} item={item}/>;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Fix - Navigation Group
					</Typography>
				);
		}
	});

	return <Box sx={{pt: 2}}>{navGroups}</Box>;
};

export default Navigation;
